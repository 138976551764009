import { dehydrate, QueryClient } from '@tanstack/react-query'
import { GetStaticProps } from 'next'
import SwapPage from './swap'

const IndexPage = () => {
  return <SwapPage />
}

export const getStaticProps: GetStaticProps = async () => {
  const queryClient = new QueryClient()
  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
    revalidate: 60 * 60, // 1 hour
  }
}

IndexPage.chains = []
IndexPage.isShowV4IconButton = false
IndexPage.mp = true

export default IndexPage
